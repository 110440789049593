import gql from 'graphql-tag'

const parcelsGetQuery = gql`
  query parcelsGet($offset: Int, $limit: Int, $criteria: Object, $sorting: Object) {
    parcelsGet(input: { limit: $limit, offset: $offset, criteria: $criteria, sort: $sorting, nested: $nested })
      @rest(type: "Parcels", method: "POST", path: "parcel/list") {
      results
      paging
    }
  }
`

export default parcelsGetQuery
