import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import reduceReducers from 'reduce-reducers'
import { reducer as commonGrid } from '@mailstep/design-system/ui/Blocks/CommonGrid'
import { reducer as dashboard } from '@mailstep/dashboard'

import admin from './admin'
import admins from './admins'
import auditsGrid from './auditsGrid'
import carriers from './carriers'
import carriersGrid from './carriersGrid'
import commonGridExt from './commonGridExt'
import complaintsGrid from './complaintsGrid'
import countries from './countries'
import dashboardData from './dashboard'
import baselinkerCredentials from './baselinkerCredentials'
import baselinkerBatch from './baselinkerBatch'
import eshops from './eshops'
import eshopsGrid from './eshopsGrid'
import expeditionItemsGrid from './expeditionItemsGrid'
import expeditions from './expeditions'
import expeditionsGrid from './expeditionsGrid'
import filters from './filters'
import inboundReceiptsGrid from './inboundReceiptsGrid'
import internalStockChangesGrid from './internalStockChangesGrid'
import notificationSettingsGrid from './notificationSettingsGrid'
import notificationsGrid from './notificationsGrid'
import missingStockGrid from './missingStockGrid'
import orderSynchronizationDetailGrid from './orderSynchronizationDetailGrid'
import ordersSynchronizationGrid from './ordersSynchronizationGrid'
import organisation from './organisation'
import organisations from './organisations'
import organisationsGrid from './organisationsGrid'
import outboundReceiptsGrid from './outboundReceiptsGrid'
import parcelsGrid from './parcelsGrid'
import partnersGrid from './partnersGrid'
import productStocks from './productStocks'
import productStocksGrid from './productStocksGrid'
import products from './products'
import productsGrid from './productsGrid'
import productsSynchronizationGrid from './productsSynchronizationGrid'
import unsyncedProductsGrid from './unsyncedProductsGrid'
import productSynchronizationDetailGrid from './productSynchronizationDetailGrid'
import reportExpedition from './report/expedition'
import reservationsGrid from './reservationsGrid'
import returnReceiptsGrid from './returnReceiptsGrid'
import statusRows from './statusRows'
import stockAdviceItemsGrid from './stockAdviceItemsGrid'
import stockAdvices from './stockAdvices'
import stockAdvicesGrid from './stockAdvicesGrid'
import stockChangesGrid from './stockChangesGrid'
import stockMovementsGrid from './stockMovementsGrid'
import suppliersGrid from './suppliersGrid'
import transfersGrid from './transfersGrid'
import ui from './ui'
import user from './user'
import users from './users'
import usersGrid from './usersGrid'
import webhooksGrid from './webhooksGrid'

const createStaticReducers = (history: History): object => ({
  admin,
  admins,
  auditsGrid,
  carriers,
  carriersGrid,
  commonGrid: reduceReducers(commonGrid, commonGridExt),
  complaintsGrid,
  baselinkerBatch,
  baselinkerCredentials,
  countries,
  dashboard,
  dashboardData,
  eshops,
  eshopsGrid,
  expeditionItemsGrid,
  expeditions,
  expeditionsGrid,
  filters,
  inboundReceiptsGrid,
  internalStockChangesGrid,
  missingStockGrid,
  notificationSettingsGrid,
  notificationsGrid,
  statusRows,
  ordersSynchronizationGrid,
  orderSynchronizationDetailGrid,
  organisation,
  organisations,
  organisationsGrid,
  outboundReceiptsGrid,
  parcelsGrid,
  partnersGrid,
  productStocks,
  productStocksGrid,
  products,
  productsGrid,
  productsSynchronizationGrid,
  unsyncedProductsGrid,
  productSynchronizationDetailGrid,
  reportExpedition,
  reservationsGrid,
  returnReceiptsGrid,
  router: connectRouter(history),
  stockAdviceItemsGrid,
  stockAdvices,
  stockAdvicesGrid,
  stockChangesGrid,
  stockMovementsGrid,
  suppliersGrid,
  transfersGrid,
  ui,
  user,
  users,
  usersGrid,
  webhooksGrid,
})

export default createStaticReducers
